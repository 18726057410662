#downloads .meta_box {
  .box {
    strong {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.42;
      color: $primary-purple;
    }

    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 88px;
    }

    p {
      font-size: 16px;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $primary-purple;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      gap: 88px;

      li {
        width: 340px;
        border: $container-border;
        border-radius: $container-radius;
        display: flex;
        padding: 30px;

        a {
          width: 100%;
        }

        .picture {
          width: 70px;
          height: 70px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 0 0;
          margin: 0 0 35px;
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 20px;

          p {
            margin: auto auto auto 0;
            font-weight: 600;
            line-height: 1;
            color: $primary-purple;
          }

          .right {
            background-color: $primary-green;
            border-radius: 50%;
            font-family: 'GTWalsheimPro-Bold';
            font-size: 32px;
            color: white;

            width: 50px;
            height: 50px;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .app {
      margin-top: 42px;
    }
  }
}

#downloads .meta_box .picture.windows {
  background-image: url(~@/assets/images/downloads/icons-big-windows@3x.png);
}

#downloads .meta_box .picture.iphone {
  background-image: url(~@/assets/images/downloads/icons-big-apple@3x.png);
}

#downloads .meta_box .picture.android {
  background-image: url(~@/assets/images/downloads/icons-big-android@3x.png);
}

#downloads .meta_box .picture.webtrader {
  background-image: url(~@/assets/images/downloads/icons-big-data@3x.png);
}

#downloads .content_box {
  /deep/ .el-dialog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      overflow-y: visible;
      .download_content {
        position: relative;
        .closeImg {
          width: 30px;
          position: absolute;
          top: 2%;
          right: 2%;
          cursor: pointer;
        }
        .btn {
          max-width: 270px;
          position: absolute;
          top: 50%;
          left: 5.5%;
          img {
            width: 200px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  #downloads .meta_box .box {
    ul {
      column-gap: 40px;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }

    li {
      width: 200px;
    }
  }

  #downloads .meta_box .box .app img {
    content: url(~@/assets/images/downloads/AppImageMoblie.png);
  }
}

@media (max-width: 550px) {
  #downloads .meta_box {
    padding: 31px 24px 24px;

    .box {
      strong {
        font-size: 20px;
      }

      &:nth-child(2) {
        margin-top: 50px;
      }

      .app {
        margin-top: 32px;
      }
    }
  }
}

@media (max-width: 400px) {
  #downloads .meta_box .box ul {
    text-align: center;
    li {
      .picture {
        margin: 0 auto 17px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 320px) {
  #downloads .meta_box .box ul li {
    text-align: center;
  }
  #downloads .meta_box .box ul li img {
    display: none;
  }
}
